import { Asset, AssetCurve, ForecastTableData } from 'interfaces';

import { COMPONENTS_TYPE_IDS } from 'constant';

import { createMonthData } from './createMonthData';

export const forecastSetTableData = (assetsPayloads: Asset[], assetsCurves: AssetCurve[], selectedOptions: Asset, tableDataLength: number): ForecastTableData => {
  const asset = assetsPayloads.filter((asset: Asset) => asset.name === selectedOptions.name)[0];

  return ({
    id: tableDataLength + 1,
    componentType: COMPONENTS_TYPE_IDS.meanReturn,
    assetName: selectedOptions?.name,
    ...createMonthData(assetsCurves, null, null, asset.id, false)
  });
};
