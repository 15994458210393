import { MONTH_ABBREV_YEAR_DATE_FORMAT } from 'constant';

import { formatDate } from 'utils/formatDate';
import { dateComparator } from 'utils/comperators';

import { textRenderer } from './textFormatter';

export const commonColumnDefs = [
  {
    field: 'scenarioName',
    headerName: 'Scenario name',
    flex: 1,
    minWidth: 240,
    maxWidth: 400,
    sort: 'asc',
    sortIndex: 0,
    cellRenderer: textRenderer
  },
  {
    field: 'portfolioName',
    headerName: 'Portfolio name',
    flex: 1,
    minWidth: 240,
    maxWidth: 400,
    cellRenderer: textRenderer
  },
  {
    field: 'timePeriodDate',
    headerName: 'Time period',
    flex: 1,
    minWidth: 160,
    maxWidth: 250,
    sort: 'asc',
    sortIndex: 1,
    comparator: dateComparator,
    cellRenderer: (params: any) => formatDate(
      params?.value as string,
      MONTH_ABBREV_YEAR_DATE_FORMAT
    ) || params?.valueFormatted
  }
];
