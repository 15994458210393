import React, { useEffect, useState } from 'react';

import { Asset, AssetCurve, AssetsFilters, AssetsFiltersWithSelectedOption } from 'interfaces';
import { DEFAULT_SCENARIO_TYPE, FILTERS_NAMES, FORECAST_TYPES_IDS, forecastTypes } from 'constant';

import {
  getDefaultAssetsDictionariesFilters,
  getNotSelectedAssetsDictionariesFilters,
  updateFiltersBySelectedOptions
} from 'utils/assetsUtils';
import { forecastSetTableData, forecastSetReturnRangeTableData } from 'utils/scenario';

import FormHeading from 'components/ui/FormHeading';
import Select from 'components/ui/Select';
import Icons from 'components/ui/Icons';
import Button from 'components/ui/Button';
import Autocomplete, { Option } from 'components/ui/Autocomplete';

import {
  Wrapper,
  StyledBox,
  FormBoxWrapper,
  FormBox,
  ButtonWrapper
} from './ScenarioForecast.styles';

export interface ScenarioForecastProps {
  returnTableData: any;
  setReturnTableData: any;
  returnRangeTableData: any;
  setReturnRangeTableData: any;
  assetsPayloads: Asset[];
  assetsCurves: AssetCurve[];
  forecastType: any;
  setForecastType: any;
}

const ScenarioForecast = (props: ScenarioForecastProps): JSX.Element => {
  const defaultSelectedOptions = {
    className: null,
    region: null,
    priceCurrency: null,
    assetClass: null,
    name: null
  };

  const [selectedOptions, setSelectedOptions] = useState<any>(defaultSelectedOptions);

  const [notSelectedAssetsPayloads, setNotSelectedAssetsPayloads] = useState<AssetsFilters>({
    [FILTERS_NAMES.name]: [],
    [FILTERS_NAMES.assetClass]: [],
    [FILTERS_NAMES.region]: [],
    [FILTERS_NAMES.priceCurrency]: []
  });

  useEffect(() => {
    setNotSelectedAssetsPayloads(
      getNotSelectedAssetsDictionariesFilters(props.assetsPayloads, props.returnTableData)
    );
  }, [props.assetsPayloads, props.returnTableData]);

  const [assetsDictionariesFilters, setAssetsDictionariesFilters] = useState<AssetsFiltersWithSelectedOption>(
    getDefaultAssetsDictionariesFilters(notSelectedAssetsPayloads)
  );

  useEffect(() => {
    setAssetsDictionariesFilters(
      getDefaultAssetsDictionariesFilters(notSelectedAssetsPayloads)
    );
  }, [notSelectedAssetsPayloads]);

  const addForecast = () => {
    if (props.forecastType.value === FORECAST_TYPES_IDS.meanReturn) {
      props.setReturnTableData([
        ...props.returnTableData,
        forecastSetTableData(props.assetsPayloads, props.assetsCurves, selectedOptions, props.returnTableData.length)
      ]);
    } else {
      props.setReturnRangeTableData([
        ...props.returnRangeTableData,
        ...forecastSetReturnRangeTableData(props.assetsPayloads, props.assetsCurves, selectedOptions, props.returnRangeTableData.length)
      ]);
    }

    props.setForecastType(DEFAULT_SCENARIO_TYPE);

    setSelectedOptions(defaultSelectedOptions);
    setNotSelectedAssetsPayloads(
      getNotSelectedAssetsDictionariesFilters(props.assetsPayloads, [...props.returnTableData, ...props.returnRangeTableData])
    );
    setAssetsDictionariesFilters(
      getDefaultAssetsDictionariesFilters(notSelectedAssetsPayloads)
    );
  };

  return (
    <Wrapper>
      <FormHeading
        withPadding={false}
        additionalComponent={(
          <Select
            name="forecastType"
            color="secondary"
            withExternalLabel
            size="small"
            withMarginBottom={false}
            label="Forecast type:"
            labelPosition="left"
            onChange={(option: any) => {
              props.setForecastType(option);
            }}
            options={forecastTypes}
            selected={props.forecastType}
          />
        )}
      >
        Set Forecast
      </FormHeading>
      <StyledBox>
        <FormBoxWrapper>
          <FormBox>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.region}
              name={FILTERS_NAMES.region}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'region',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  notSelectedAssetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  region: selectedOption?.value
                });
              }}
              label="Geography"
              placeholder="Geography"
              options={
                assetsDictionariesFilters.region.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.region],
                  label: asset[FILTERS_NAMES.region]
                }))
              }
              value={selectedOptions.region}
            />
          </FormBox>
          <FormBox>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.priceCurrency}
              name={FILTERS_NAMES.priceCurrency}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'priceCurrency',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  notSelectedAssetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  priceCurrency: selectedOption?.value
                });
              }}
              label="Currency"
              placeholder="Currency"
              options={
                assetsDictionariesFilters.priceCurrency.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.priceCurrency],
                  label: asset[FILTERS_NAMES.priceCurrency]
                }))
              }
              value={selectedOptions.priceCurrency}
            />
          </FormBox>
          <FormBox wider>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.assetClass}
              name={FILTERS_NAMES.assetClass}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'assetClass',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  notSelectedAssetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  assetClass: selectedOption?.value
                });
              }}
              label="Asset Classes"
              placeholder="Choose an option"
              options={
                assetsDictionariesFilters.assetClass.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.assetClass],
                  label: asset[FILTERS_NAMES.assetClass]
                }))
              }
              value={selectedOptions.assetClass}
            />
          </FormBox>
        </FormBoxWrapper>
        <FormBoxWrapper>
          <FormBox wider>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.name}
              name={FILTERS_NAMES.name}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'name',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  notSelectedAssetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  name: selectedOption?.value
                });
              }}
              label="Search Index Variable"
              placeholder="Choose an option"
              options={
                assetsDictionariesFilters.name.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.name],
                  label: asset[FILTERS_NAMES.name]
                }))
              }
              value={selectedOptions.name}
            />
          </FormBox>
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              disabled={!selectedOptions.name}
              endIcon={(
                <Icons iconType="plus" size={10} />
              )}
              onClick={addForecast}
            >
              Add Forecast
            </Button>
          </ButtonWrapper>
        </FormBoxWrapper>
      </StyledBox>
    </Wrapper>
  );
};

export default ScenarioForecast;
