import { COMPONENTS_TYPE_IDS, MEAN_RETURN_TYPE_LABELS } from 'constant';

export const getMinMaxLabel = (componentType: number): string | null => {
  switch (componentType) {
    case COMPONENTS_TYPE_IDS.meanReturn: return null;
    case COMPONENTS_TYPE_IDS.meanReturnRangeMin: return MEAN_RETURN_TYPE_LABELS.min;
    case COMPONENTS_TYPE_IDS.meanReturnRangeMax: return MEAN_RETURN_TYPE_LABELS.max;

    default: return null;
  }
};
