import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Notification, AppDispatch, RootState } from 'interfaces';

import Loader from 'components/ui/Loader';
import Notifications from 'components/ui/Notifications';

import Layout from 'components/Layout';
import CreateScenarioComponent from 'components/CreateScenario';

import { getAssets, getAssetsCurves } from 'features/assets/assetsSlice';

import { getScenario, addOrUpdateScenario, resetState } from './createScenarioSlice';

const CreateScenario = (): JSX.Element => {
  const history = useHistory();
  const { scenarioId }: any = useParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch, scenarioId]);

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getAssetsCurves());
  }, [dispatch]);

  const assetsPayloads = useSelector((state: RootState) => state.assets.assetsPayloads);
  const assetsCurves = useSelector((state: RootState) => state.assets.assetsCurves);

  useEffect(() => {
    if (scenarioId && assetsPayloads.length) {
      dispatch(getScenario({ scenarioId }));
    }
  }, [dispatch, scenarioId, assetsPayloads]);

  const forecastSerialID = useSelector((state: RootState) => state.createScenario.forecastSerialID);

  const notificationMsg = useSelector((state: RootState) => state.createScenario.notification);
  const initialValues = useSelector((state: RootState) => state.createScenario.initialValues);
  const scenarioDetails = useSelector((state: RootState) => state.createScenario.scenarioDetails);
  const returnTableData = useSelector((state: RootState) => state.createScenario.returnTableData);
  const returnRangeTableData = useSelector((state: RootState) => state.createScenario.returnRangeTableData);

  const assetsLoading = useSelector((state: RootState) => state.assets.loading);
  const loading = useSelector((state: RootState) => state.createScenario.loading);

  const [notification, setNotification] = useState<Notification>(notificationMsg);

  useEffect(() => {
    setNotification(notificationMsg);
  }, [setNotification, notificationMsg]);

  return (
    <Layout>
      <CreateScenarioComponent
        assetsPayloads={assetsPayloads}
        assetsCurves={assetsCurves}
        initialValues={initialValues}
        scenarioDetails={scenarioDetails}
        returnTableData={returnTableData}
        returnRangeTableData={returnRangeTableData}
        createScenario={({ scenarioDetails, returnTableData }: any) => {
          dispatch(
            // @ts-ignore
            addOrUpdateScenario({
              scenarioId, forecastSerialID, scenarioDetails, returnTableData, history
            })
          );
        }}
      />
      {
        loading || assetsLoading ? (
          <Loader loaderType="absolute" semiCovered />
        ) : null
      }
      {
        notification.message && notification.open ? (
          <Notifications
            notification={notification}
            setNotification={setNotification}
          />
        ) : null
      }
    </Layout>
  );
};

export default CreateScenario;
