import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { LAYOUT_DEFAULT_H_MARGIN, MAIN_HEADER_HEIGHT } from 'constant';
import { hexToRgbMixin } from 'styles/mixins';

import Icons from 'components/ui/Icons';

interface TableWrapperProps {
  withSearch: boolean | undefined;
  selectedRow: boolean | undefined;
  additionalValueToHeightSubtraction: number | 0;
}

const TableWrapper = styled.div<TableWrapperProps>`
  --ag-header-height: 50px;
  --ag-header-foreground-color: ${(props) => props.theme.colorMono.white};
  --ag-header-background-color: #014547;
  --ag-border-color: #e3e3e3;

  position: relative;
  height: calc(100vh - ${MAIN_HEADER_HEIGHT}px - (2 * ${LAYOUT_DEFAULT_H_MARGIN}px) - ${(props) => props.additionalValueToHeightSubtraction}px);

  && {
    .ag-react-container {
      width: 100%;
    }
    .ag-status-bar-right {
      width: 100%;
    }
    .ag-header-container,
    .ag-header-row.ag-header-row-column,
    .ag-header-row.ag-header-row-column-filter {
      min-width: 100% !important;
    }

    .ag-filter-toolpanel-header,
    .ag-filter-toolpanel-search,
    .ag-status-bar,
    .ag-header-row,
    .ag-panel-title-bar-title,
    .ag-multi-filter-group-title-bar {
      font-weight: 500;
    }
    ${(props) => props.withSearch && css`
      .ag-column-drop-horizontal {
        background: ${props.theme.colorMono.white};
      }
    `};

    .ag-checkbox-input-wrapper:focus-within,
    .ag-checkbox-input-wrapper:active {
      outline: none;
      box-shadow: 0 0 2px .1rem ${(props) => hexToRgbMixin(props.theme.color.primary, 0.2)};
    }
    .ag-checkbox-input-wrapper,
    .ag-checkbox-input-wrapper input {
      background: none;
    }
    .ag-header-cell .ag-checkbox-input-wrapper::after {
      color: ${(props) => props.theme.colorMono.white};
    }
    .ag-checkbox-input-wrapper::after {
      color: ${(props) => props.theme.colorMono.black};
    }
    .ag-checkbox-input-wrapper.ag-indeterminate,
    .ag-header-cell .ag-checkbox-input-wrapper.ag-checked,
    .ag-checkbox-input-wrapper.ag-checked {
      background: ${(props) => props.theme.colorMono.white};

      &::after {
        color: ${(props) => props.theme.color.primary};
      }
    }
    .ag-row-selected::before {
      background: ${(props) => hexToRgbMixin(props.theme.color.primary, 0.2)};
    }

    .ag-row-focus {
      background: ${(props) => hexToRgbMixin(props.theme.color.primary, 0.2)} !important;
    }
    .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
    .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
    .ag-ltr .ag-cell-range-single-cell,
    .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
    .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
    .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
    .ag-rtl .ag-cell-range-single-cell,
    .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
      border-color: transparent;
      box-shadow: none;
    }
  }

  /* Manager table */
  .ag-column-drop-wrapper {
    position: sticky;
  }
  .ag-column-drop-horizontal {
    height: 50px;
    background: #f2f2f2;
  }
  .ag-root-wrapper-body.ag-layout-normal {
    flex: 1 1 auto;
    height: 0;
    min-height: 0;
    border: none;

    ${(props) => props.selectedRow && css`
      margin-top: 40px;
    `};
  }
  .ag-ltr .ag-cell {
    display: flex;
    align-items: center;
  }
  .ag-ltr input[class^='ag-'][type='text'] {
    color: black;
  }
  .ag-header-icon {
    color: white;
  }
  .ag-header-row .ag-header-cell {
    &:last-of-type {
      .ag-header-cell-resize {
        display: none;
      }
    }
  }
  .ag-header-cell-resize::after {
    background: rgba(186, 191, 199, 0.5);
  }
  .ag-header-row-column-filter {
    background-color: whitesmoke;
  }
  .ag-floating-filter-button-button {
    color: gray;
  }
  .custom-header-menu-button {
    float: right;
    margin: 0 0 0 3px;
    opacity: 1;
  }
  .custom-header-menu-button:hover {
    display: inherit;
    opacity: 1;
  }
  .custom-header-label {
    float: left;
    margin: 0 0 0 3px;
    padding-right: 55px;
  }
  .custom-sort-down-label {
    float: left;
    opacity: 0;
  }
  .custom-sort-down-label:hover {
    float: left;
    opacity: 1;
  }
  .custom-sort-up-label {
    float: left;
  }
  .custom-sort-remove-label {
    float: left;
    font-size: 11px;
  }
  .custom-sort-down-label,
  .custom-sort-up-label,
  .custom-sort-remove-label {
    margin: 0 -5px 0 3px;
  }
  .ag-ltr .ag-floating-filter-button {
    z-index: 1;
    margin-left: -26px;
  }
  input[class^='ag-'][type='text'] {
    border-width: 0;
    border-bottom: 1.2px solid #8d8d8d;
    border-radius: 0;
  }
  .ag-row-odd {
    background: rgba(188 188 188 / .1);
  }
  .ag-row:hover {
    background: ${(props) => hexToRgbMixin(props.theme.color.primary, 0.08)} !important;
  }
  .ag-row-hover:not(.ag-full-width-row)::before {
    background: inherit !important;
  }
  .action-cell {
    .table-header-options {
      display: none;
    }
  }
  .action-icons {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    padding: 4px;
    border: 1px solid #d7d7d7;
    border-radius: 50%;
  }
  .ag-cell-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ag-cell-value {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ag-column-drop-cell-button {
    display: flex;
    opacity: 1;
  }
  .ag-column-drop-cell-drag-handle {
    display: none;
    margin-left: 12px;
  }
  .ag-ltr .ag-menu-option-icon {
    padding-left: 12px;
    opacity: .25;
  }
  .ag-tab-selected {
    border-color: ${(props) => props.theme.color.secondary};
    color: ${(props) => props.theme.color.secondary};
  }
  .ag-header-cell-menu-button:hover,
  .ag-side-button-button:hover,
  .ag-tab:hover,
  .ag-panel-title-bar-button:hover,
  .ag-header-expand-icon:hover,
  .ag-column-group-icons:hover,
  .ag-group-expanded .ag-icon:hover,
  .ag-group-contracted .ag-icon:hover,
  .ag-chart-settings-prev:hover,
  .ag-chart-settings-next:hover,
  .ag-group-title-bar-icon:hover,
  .ag-column-select-header-icon:hover,
  .ag-floating-filter-button-button:hover,
  .ag-filter-toolpanel-expand:hover,
  .ag-chart-menu-icon:hover {
    color: ${(props) => props.theme.color.secondary};
    opacity: .7;
  }
  .ag-theme-alpine .ag-menu-header {
    color: #9c9c9c;
  }
  .ag-floating-filter-button-button .ag-icon-filter::before {
    font-size: 14px;
  }
  .ag-row-drag,
  .ag-floating-filter-button-button .ag-icon-filter {
    opacity: .5;
  }
`;

const SelectedRowsInfo = styled.div`
  position: absolute;
  top: 50px;
  right: 1px;
  left: 1px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  padding: 0 16px;
  background: #a87791;
  color: ${(props) => props.theme.colorMono.white};
`;

const SearchWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: -7px;
  z-index: 2;
  width: 30%;
  margin-right: 20px;
`;

// @ts-ignore
const StyledSearchIcons = styled(Icons)`
  margin-left: 7px;
`;

const SelectedRowsButtonsList = styled.ul`
  display: flex;
  gap: 10px;
  list-style: none;
`;

const SelectedRowsButtonsItem = styled.li`
  line-height: 1.2;
  & + & {
    padding-left: 10px;
    border-left: 1px solid;
  }
`;

export {
  TableWrapper,
  SelectedRowsInfo,
  SearchWrapper,
  StyledSearchIcons,
  SelectedRowsButtonsList,
  SelectedRowsButtonsItem
};
