import React from 'react';

import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { ScenarioForecast } from 'interfaces';

import ScenarioForecastInputsTable from 'components/ScenarioForecastInputsTable';

import {
  AssetsWrapper
} from './ScenarioViewAssetsTable.styles';

export interface ScenarioViewAssetsTableProps {
  scenarioForecast: ScenarioForecast;
}

const ScenarioViewAssetsTable = (props: ScenarioViewAssetsTableProps): JSX.Element => {
  const timeColumnDefault = {
    minWidth: 90,
    maxWidth: 530,
    cellClass: 'center-row-container'
  };

  const columnDefs: Array<ColGroupDef | ColDef> = [
    {
      field: 'assetName',
      headerName: 'Asset Class',
      flex: 1,
      minWidth: 380,
      maxWidth: 680,
      editable: false,
      pinned: 'left',
      suppressMovable: true,
      cellClass: 'asset-class'
    },
    {
      ...timeColumnDefault,
      field: 'M3',
      headerName: '3M'
    },
    {
      ...timeColumnDefault,
      field: 'M6',
      headerName: '6M'
    },
    {
      ...timeColumnDefault,
      field: 'M12',
      headerName: '12M'
    },
    {
      ...timeColumnDefault,
      field: 'M18',
      headerName: '18M'
    },
    {
      ...timeColumnDefault,
      field: 'M24',
      headerName: '2Y'
    },
    {
      ...timeColumnDefault,
      field: 'M36',
      headerName: '3Y'
    },
    {
      ...timeColumnDefault,
      field: 'M48',
      headerName: '4Y'
    },
    {
      ...timeColumnDefault,
      field: 'M60',
      headerName: '5Y'
    },
    {
      ...timeColumnDefault,
      field: 'M120',
      headerName: '10Y'
    }
  ];

  return (
    <AssetsWrapper>
      {
        props.scenarioForecast.components.length ? (
          <ScenarioForecastInputsTable
            rowData={props.scenarioForecast.components}
            columnDefs={columnDefs}
            withoutColors
          />
        ) : 'No components'
      }
    </AssetsWrapper>
  );
};

export default ScenarioViewAssetsTable;
