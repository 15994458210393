// GENERAL

/* Navigation */
export const NAVIGATION_ITEM_OPEN_KEY = 'menuItemOpen';
export const MAIN_NAVIGATION_OPEN_KEY = 'mainNavigationOpen';

export enum BUTTON_ALLOW_COMPONENTS_TYPES {
  button = 'button',
  link = 'link',
  anchor = 'anchor',
  other = 'other',
  materialAnchor = 'a'
}

/* Default messages  */
export const defaultMessages = {
  required: 'This field is required',
  fieldMustBeDateFormat: 'Field must be in date format: mm/yyyy'
};

export const MAIN_CONTAINER_ID = 'main-container';
export const MAIN_HEADER_ID = 'main-header';
export const MAIN_NAVIGATION_ID = 'main-navigation';
export const SIDE_BAR_MENU_ID = 'sidebar-menu';
export const SIDE_PANEL_ID = 'side-panel';
export const SET_FORECAST_ID = 'set-forecast';
export const SCENARIO_EXPLORER_FILTERS_ID = 'scenario-explorer-filters';

export enum FILTERS_NAMES {
  name = 'name',
  assetClass = 'assetClass',
  region = 'region',
  priceCurrency = 'priceCurrency'
}

export const MONTH_YEAR_DATE_FORMAT = 'MM/yyyy';
export const MONTH_ABBREV_YEAR_DATE_FORMAT = 'MMM yyyy';
export const DAY_MONTH_YEAR_DATE_FORMAT = 'dd/MM/yyyy';

export const GENERAL_DATE_PICKER_VIEWS = ['year', 'month', 'day'];
export const MONTH_YEAR_DATE_PICKER_VIEWS = ['year', 'month'];

export const GENERAL_DATE_PICKER_OPEN_TO = {
  year: 'year',
  month: 'month',
  day: 'day'
};

// DASHBOARD
export const JOB_DEFAULT_PAGE_SIZE = 30;

export enum DASHBOARD_ANALYTICS_DATA_SET_TYPES {
  summary = 'summary',
  assetAttribute = 'assetattrib',
  assetStats = 'assetstats'
}
export enum OBJECT_NAME_TYPES {
  assetAttribData = 'assetAttribData',
  assetStatsData = 'assetStatsData'
}

// SCENARIO
export const SCENARIO_TYPES_OPTIONS = [
  { label: 'None', value: '0' },
  { label: 'Outlook', value: '1' },
  { label: 'Downside', value: '2' },
  { label: 'Thematic', value: '3' },
  { label: 'Geopolitical', value: '4' },
  { label: 'Climate', value: '5' }
];

export const KEY_CODE_ENTER = 'Enter';

export const COMPONENTS_MONTH_PREFIX = 'M';

export const COMPONENTS_TABLE_TIME_LABELS: any = {
  1: 'M3',
  2: 'M6',
  3: 'M12',
  4: 'M18',
  5: 'M24',
  6: 'M36',
  7: 'M48',
  8: 'M60',
  9: 'M120'
};

export const FORECAST_SET_LENGTH = 9; // length of COMPONENTS_TABLE_TIME_LABELS - 10 years - @TODO: from API

export const FORECAST_TYPES_IDS = {
  meanReturn: 1,
  meanReturnRange: 2
};
export const COMPONENTS_TYPE_IDS = {
  meanReturn: 1,
  meanReturnRangeMin: 2,
  meanReturnRangeMax: 3
};
export const MEAN_RETURN_TYPE_LABELS = {
  min: 'Min',
  max: 'Max'
};
export const forecastTypes = [
  {
    value: FORECAST_TYPES_IDS.meanReturn,
    label: 'Mean Return'
  },
  {
    value: FORECAST_TYPES_IDS.meanReturnRange,
    label: 'Mean Return Range'
  }
];
export const DEFAULT_SCENARIO_TYPE = forecastTypes[0];

// LAYOUT
export const MAIN_HEADER_HEIGHT = 75;
export const MAIN_NAV_WIDTH = 60;
export const MAIN_NAV_WIDE_WIDTH = 240; // 300
export const PAGE_BUTTONS_WRAPPER_HEIGHT = 75;
export const HIDDEN_SIDEBAR_MARGIN = 10;
export const LAYOUT_DEFAULT_H_MARGIN = 25;

// JOB SETTINGS
export const DEFAULT_JOB_SETTING = {
  numberOfSettings: 1000000, // min: 1000 max: 100000, default: 10000
  modelHorizon: 7, // not to change
  samplingInterval: 26, // 4 - 1 m, 13 - 3 m, 26 - 6 m, 52 - 1 year
  forecastHorizon: 208, // 104 // min: 52 max: 520, increase by 52
  reportingCurrency: 'USD'
};

export const JOB_SETTINGS_SAMPLING_INTERVAL_OPTIONS = [
  { label: '1M', value: '4' },
  { label: '3M', value: '13' },
  { label: '6M', value: '26' }, // default
  { label: '1Y', value: '52' }
];

export const JOB_SETTINGS_FORECAST_HORIZON_OPTIONS = [
  { label: '1Y', value: '52' },
  { label: '2Y', value: '104' },
  { label: '3Y', value: '156' },
  { label: '4Y', value: '208' }, // default
  { label: '5Y', value: '260' },
  { label: '6Y', value: '312' },
  { label: '7Y', value: '364' },
  { label: '8Y', value: '416' },
  { label: '9Y', value: '468' },
  { label: '10Y', value: '520' }
];

export const MANAGER_TABLE_HEADER_SORTING_STATES = {
  active: 'active',
  inactive: 'inactive'
};

export const ENTER_KAY_NAME = 'Enter';
