import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { JobViewData } from 'interfaces';

import { fetchCalcSetting, fetchScenarioSet, fetchScenario, fetchPortfolio } from 'api';

const initialState: JobViewData = {
  jobName: null,
  portfolioList: [],
  scenarioList: [],
  // @TODO: add Rules
  rulesList: [],
  scenarioSets: [],
  portfolios: [],
  loading: false
};

export const getCalcSettings = createAsyncThunk(
  'jobView/getCalcSettings',
  // @ts-ignore
  async ({ calcId } : any) => fetchCalcSetting(calcId)
    .then((response: any) => {
      const { name, scenarioSets, portfolios } = response;

      return {
        jobName: name,
        scenarioSets,
        portfolios
      };
    })
);

export const getScenarioSets = createAsyncThunk(
  'jobView/getScenarioSets',
  // @ts-ignore
  async ({ scenarioSets }: any) => {
    const scenarioSetIds = scenarioSets.map((el: any) => el.scenarioSetSerialID);

    return Promise.all(
      [
        ...scenarioSetIds.map((id: any) => fetchScenarioSet(id))
      ]
    )
      .then((responses: any) => {
        const data = responses.map((el: any) => el.data);
        const scenarios = data.map((el: any) => el.scenarios);

        // @TODO: only unique scenarioSerialID
        const allScenarioIds = scenarios.reduce((acc: any[], current: any) => acc
          .concat(current.map((el: any) => el.scenarioSerialID)), []);

        return Promise.all(
          [
            ...allScenarioIds.map((id: any) => fetchScenario(id))
          ]
        )
          .then((scenariosResponses: any) => scenariosResponses.map((el: any) => el.data));
      });
  }
);

export const getPortfolios = createAsyncThunk(
  'jobView/getPortfolios',
  // @ts-ignore
  async ({ portfolios }: any) => {
    const portfoliosIds = portfolios.map((el: any) => el.portfolioSerialID);

    return Promise.all(
      [
        ...portfoliosIds.map((id: any) => fetchPortfolio(id))
      ]
    );
  }
);

const jobVew = createSlice({
  name: 'jobVew',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCalcSettings.pending, (state, action) => ({
        ...state,
        loading: true
      }))
      .addCase(getCalcSettings.fulfilled, (state, action) => {
        const { jobName, scenarioSets, portfolios } = action.payload;

        return {
          ...state,
          jobName,
          scenarioSets,
          portfolios,
          loading: false
        };
      })
      // @TODO: Handle error
      .addCase(getCalcSettings.rejected, (state) => ({
        ...state,
        loading: false
      }))
      .addCase(getScenarioSets.pending, (state, action) => ({
        ...state,
        loading: true
      }))
      .addCase(getScenarioSets.fulfilled, (state, action) => ({
        ...state,
        scenarioList: action.payload,
        loading: false
      }))
      // @TODO: Handle error
      .addCase(getScenarioSets.rejected, (state) => ({
        ...state,
        loading: false
      }))
      .addCase(getPortfolios.pending, (state, action) => ({
        ...state,
        loading: true
      }))
      .addCase(getPortfolios.fulfilled, (state, action) => ({
        ...state,
        portfolioList: action.payload,
        loading: false
      }))
      // @TODO: Handle error
      .addCase(getPortfolios.rejected, (state) => ({
        ...state,
        loading: false
      }));
  }
});

export default jobVew.reducer;
