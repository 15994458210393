import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TypographyProps } from 'components/ui/Typography';

const Wrapper = styled.p<TypographyProps>`
  ${(props) => props.size === 'normal' && css`
    font-size: 14px;
  `};
  ${(props) => props.size === 'small' && css`
    font-size: 12px;
  `};
  ${(props) => props.size === 'medium' && css`
    font-size: 16px;
  `};
  ${(props) => props.size === 'big' && css`
    font-size: 18px;
  `};
  ${(props) => props.size === 'superBig' && css`
    font-size: 20px;
  `};

  ${(props) => props.color === 'primary' && css`
    color: ${props.theme.color.primary};
  `};
  ${(props) => props.color === 'secondary' && css`
    color: ${props.theme.color.secondary};
  `};

  ${(props) => props.withMarginBottom && css`
    margin-bottom: 20px;
  `};
`;

export {
  Wrapper
};
