import { Asset, AssetCurve } from 'interfaces';

import {
  COMPONENTS_MONTH_PREFIX,
  COMPONENTS_TABLE_TIME_LABELS,
  FORECAST_SET_LENGTH
} from 'constant';

import { getAssetReturn } from './getAssetReturn';

export const createMonthData = (assetsCurves: AssetCurve[], targetDate: number | null, targetValue: number | null, id: string, edit: boolean): any => {
  const data = Array.from({ length: FORECAST_SET_LENGTH }, (v, index) => {
    const label = COMPONENTS_TABLE_TIME_LABELS[index + 1];
    const labelId = Number(label.replace(COMPONENTS_MONTH_PREFIX, ''));

    const defaultValues = !edit ? getAssetReturn(assetsCurves, labelId, id) : null;

    return {
      label,
      value: targetDate === labelId
        ? Number(Number((targetValue || 0) * 100).toFixed(2))
        : defaultValues
    };
  });

  return data.reduce((obj, item) => Object
    .assign(obj, { [item.label]: item.value }), {});
};
