import React, { ElementType } from 'react';

import {
  Wrapper
} from './Typography.styles';

export interface TypographyProps {
  children: React.ReactNode;
  className?: string;
  as?: (ElementType<any> & string) | undefined;
  color?: 'text' | 'primary' | 'secondary';
  size?: 'normal' | 'small' | 'medium' | 'big' | 'superBig';
  withMarginBottom?: boolean;
}
const defaultProps = {
  className: '',
  color: 'text',
  size: 'normal',
  as: 'p',
  withMarginBottom: false
};

const Typography = (props: TypographyProps): JSX.Element => (
  <Wrapper
    className={props.className}
    as={props.as}
    color={props.color}
    size={props.size}
    withMarginBottom={props.withMarginBottom}
  >
    {props.children}
  </Wrapper>
);

Typography.defaultProps = defaultProps;

export default Typography;
