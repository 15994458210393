import { getAssetReturn } from './getAssetReturn';

const getCorrectValue = (assetsPayloads: any[], id: string, label: string, valueOne: any, valueTwo: any) => {
  const labelId = Number(label.replace('M', '')); // @TODO: 'M' prefix move to constant

  if (Number.isNaN(labelId) || valueOne === valueTwo) {
    return valueTwo || valueOne;
  }

  if (valueOne !== getAssetReturn(assetsPayloads, labelId, id)) {
    return valueOne;
  }
  if (valueTwo !== getAssetReturn(assetsPayloads, labelId, id)) {
    return valueTwo;
  }

  return null;
};

export const getUniqueComponents = (
  assetsPayloads: any[],
  components: any[],
  edit: boolean
): any[] => components.reduce((acc, current) => {
  const uniqueArray = [...acc];
  const currentDataInArrayIndex = acc.findIndex(
    (el: any) => el.assetName === current.assetName && el.componentType === current.componentType
  );

  if (currentDataInArrayIndex > -1) {
    const notUniqueElement = acc[currentDataInArrayIndex];
    const newUniqueObject: any = {};

    Object.entries(notUniqueElement).forEach(([notUniqueElementKey, notUniqueElementValue]) => {
      Object.entries(current).forEach(([currentKey, currentValue]) => {
        if (currentKey === notUniqueElementKey) {
          const valueWhiteEditing = currentValue === null ? notUniqueElementValue : currentValue;
          newUniqueObject[currentKey] = edit ? valueWhiteEditing : getCorrectValue(
            assetsPayloads,
            current.assetID,
            currentKey,
            notUniqueElementValue,
            currentValue
          );
        }
      });
    });

    uniqueArray[currentDataInArrayIndex] = newUniqueObject;
  } else {
    uniqueArray.push(current);
  }

  return uniqueArray;
}, []);
