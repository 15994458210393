import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Switch, FormControlLabel } from '@mui/material';

import Typography from 'components/ui/Typography';

interface TypographyStyledProps {
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const StyledSwitch = styled(Switch)`
  && {
    padding: 0;
    width: 32px;
    height: 16px;
    .MuiButtonBase-root {
      padding: 2px;
    }
    .MuiTypography-body1 {
      margin-top: -.3rem;
    }
    & .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
    }
  }
  & .MuiButtonBase-root.Mui-checked {
    transform: translateX(16px);
  }
  & .Mui-checked {
    & .MuiSwitch-thumb {
      &::before {
        content: '\\e914';
        display: block;
        margin-left: 2px;
        margin-top: 1px;
        font-family: ${(props) => props.theme.fontFamily.icons}, sans-serif !important;
        font-size: 8px;
        color: ${(props) => props.theme.color.secondary};
      }
    }
  }
  & .Mui-checked:not(.Mui-disabled) {
    color: ${(props) => props.theme.colorMono.white};
  }
  & .Mui-checked:not(.Mui-disabled) + .MuiSwitch-track {
    background: ${(props) => props.theme.color.secondary};
    opacity: 1;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
`;

// @ts-ignore
const TypographyStyled = styled(Typography)<TypographyStyledProps>`
  color: inherit;

  ${(props) => props.labelPlacement === 'start' && css`
    margin-right: 15px;
  `};
  ${(props) => props.labelPlacement === 'end' && css`
    margin-left: 15px;
  `};
`;

export {
  StyledSwitch,
  StyledFormControlLabel,
  TypographyStyled
};
