import { Asset, AssetsFiltersWithSelectedOption } from 'interfaces';
import { FILTERS_NAMES } from 'constant';

import { findUnique } from 'utils/findUnique';

export const getNotSelectedAssetsDictionariesFilters = (assets: Asset[], tableData: any): any | AssetsFiltersWithSelectedOption => {
  const addedAssets = tableData.map((el: any) => el.assetName);
  const newAssets = assets.filter((el) => addedAssets.indexOf(el.name) === -1);

  return {
    [FILTERS_NAMES.name]: newAssets,
    [FILTERS_NAMES.assetClass]: findUnique(newAssets, FILTERS_NAMES.assetClass),
    [FILTERS_NAMES.region]: findUnique(newAssets, FILTERS_NAMES.region),
    [FILTERS_NAMES.priceCurrency]: findUnique(newAssets, FILTERS_NAMES.priceCurrency)
  };
};
