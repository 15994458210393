import { AssetCurve, AssetForwardCurve } from 'interfaces';

export const getAssetReturn = (assetsCurves: AssetCurve[], labelId: number, id: string): number | null => {
  const asset: AssetCurve = assetsCurves?.filter((el: AssetCurve) => el.id === id)[0];

  const returnValue = asset?.forwardCurve?.filter(
    (el: AssetForwardCurve) => el.tenor === labelId
  )?.[0]?.return * 100 || 0;

  return Number(Number(returnValue).toFixed(2)) || null;
};
