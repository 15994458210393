import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { hexToRgbMixin } from 'styles/mixins';

interface TableWrapperProps {
  withActions: boolean | undefined;
  withoutColors: boolean | undefined;
  editable: boolean | undefined;
}

const TableWrapper = styled.div<TableWrapperProps>`
  height: 100%;

  && {
    .ag-theme-alpine {
      --ag-header-background-color: ${(props) => props.theme.color.secondary};
      --ag-header-foreground-color: ${(props) => props.theme.colorMono.white};
    }

    ${(props) => props.withoutColors && css`
      .ag-theme-alpine {
        --ag-header-background-color: #e8e8e8;
        --ag-header-foreground-color: ${props.theme.colorMono.black};
      }
    `};
  }

  ${(props) => props.withActions && css`
    margin-right: -25px;
  `};

  .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
    min-height: auto;
    margin: 0;
    margin-top: 0 !important;
    border: none;
  }
  .ag-root-wrapper.ag-layout-normal {
    height: auto;
  }
  .ag-ltr .ag-cell {
    display: flex;
    align-items: center;
  }

  /* Scenario table */
  .lock-pinned {
    border: 0;
    background: ${(props) => props.theme.colorMono.white};
  }
  .asset-class {
    border: 0;
  }
  .asset-class:focus {
    border: 0;
    box-shadow: none;
  }
  .cross-button-css {
    opacity: .2;
  }
  .cross-button-css:hover {
    opacity: 1;
  }
  .cross-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin: -26px;
    background: ${(props) => props.theme.colorMono.white};
    opacity: .2;
  }
  .cross-icon-container:hover {
    opacity: 1;
  }
  .ag-theme-alpine .ag-pinned-right-header {
    background: ${(props) => props.theme.colorMono.white};
  }
  .ag-theme-alpine .ag-pinned-left-header {
    border: 1px solid #e8e8e8;
    background: ${(props) => props.theme.colorMono.white};
    font-size: 14px;
    line-height: 19px;
    color: ${(props) => props.theme.colorMono.black};
  }
  .ag-theme-alpine .ag-pinned-left-header .ag-header-cell-label {
    justify-content: left;
    font-size: 14px;
    line-height: 19px;
    color: ${(props) => props.theme.colorMono.black};
  }
  .ag-header-cell-label {
    justify-content: center;
    font-size: 18px;
    line-height: 25px;
  }
  .ag-theme-alpine .ag-header {
    border-bottom: none;
  }
  .ag-theme-alpine .ag-cell.negative {
    background: rgba(168 119 145 / .25);
    color: ${(props) => props.theme.color.primary};
  }
  .ag-theme-alpine .ag-cell.positive {
    background: #e4feea;
    color: ${(props) => props.theme.color.secondary};
  }
  .ag-theme-alpine .ag-ltr .ag-cell {
    height: 100%;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #e8e8e8;
    border-collapse: collapse;
  }
  .ag-theme-alpine .ag-ltr .ag-cell:focus {
    border-color: transparent;
    border-left-color: #e8e8e8;
    box-shadow: none;

    ${(props) => props.editable && css`
      border: 1px solid ${props.theme.color.secondary};
      box-shadow: inset 0 0 4px 2px rgba(1, 69, 71, .25);
    `};
  }
  .ag-row-odd .ag-row-no-focus .ag-row a.g-row-level-0 .ag-row-position-absolute .ag-row-last {
    border-bottom: none;
  }
  .ag-theme-alpine .ag-pinned-right-cols-container .ag-row,
  .ag-theme-alpine .ag-pinned-right-cols-container .ag-row:hover {
    border-color: ${(props) => props.theme.colorMono.white};
  }
  .ag-theme-alpine .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left, .ag-cell-range-single-cell) {
    padding: 0;
    border: 0;
  }
  .ag-theme-alpine .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right, .ag-cell-range-single-cell) {
    border-color: #e8e8e8;
    border-left-style: solid;
  }
  .ag-theme-alpine .ag-header-cell-menu-button:hover {
    display: none;
    color: transparent;
  }
  .ag-theme-alpine .ag-header-cell-menu-button {
    display: none;
    color: transparent;
  }
  .ag-theme-alpine .ag-row {
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #e8e8e8;
    border-collapse: collapse;
  }
  .ag-theme-alpine .ag-row::before {
    background: ${(props) => hexToRgbMixin(props.theme.color.primary, 0.08)};
  }
  .ag-theme-alpine .ag-row-odd {
    background: #f6f6f6;
  }
  .center-row-container {
    justify-content: center;
    color: ${(props) => props.theme.color.secondary};
  }
  .ag-pinned-right-cols-container {
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #e8e8e8;
    border-collapse: collapse;
  }
  .ag-theme-alpine .ag-root-wrapper {
    border: none;
  }
  .ag-theme-alpine .ag-header-cell {
    border-left: 1px solid rgba(255 255 255 / .25);
  }
  .ag-theme-alpine input[class^='ag-'][type='text'] {
    border: 0;
    text-align: center;
  }
  .ag-theme-alpine input[class^='ag-']:not([type]):focus,
  .ag-theme-alpine input[class^='ag-'][type='text']:focus,
  .ag-theme-alpine input[class^='ag-'][type='number']:focus,
  .ag-theme-alpine input[class^='ag-'][type='tel']:focus,
  .ag-theme-alpine input[class^='ag-'][type='date']:focus,
  .ag-theme-alpine input[class^='ag-'][type='datetime-local']:focus,
  .ag-theme-alpine textarea[class^='ag-']:focus {
    border-color: ${(props) => hexToRgbMixin(props.theme.color.secondary, 0.4)};
    outline: none;
    box-shadow: 0 0 2px .1rem ${(props) => hexToRgbMixin(props.theme.color.secondary, 0.4)};
  }

  .ag-theme-alpine .ag-filter-toolpanel-header,
  .ag-theme-alpine .ag-filter-toolpanel-search,
  .ag-theme-alpine .ag-status-bar,
  .ag-theme-alpine .ag-header-row,
  .ag-theme-alpine .ag-panel-title-bar-title,
  .ag-theme-alpine .ag-multi-filter-group-title-bar {
    font-weight: 400;
  }
  .ag-horizontal-left-spacer, .ag-horizontal-right-spacer {
    overflow-x: hidden;
  }
  .row-span {
    z-index: 2;
  }
  .ag-row .ag-cell.cell-span {
    border-bottom-width: 1px;
    background-color: ${(props) => props.theme.colorMono.white};
  }
  .min-max-label {
    justify-content: center;
  }
`;

export {
  TableWrapper
};
