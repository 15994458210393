import { Asset, AssetCurve, ForecastTableData } from 'interfaces';

import { COMPONENTS_TYPE_IDS, MEAN_RETURN_TYPE_LABELS } from 'constant';

import { createMonthData } from './createMonthData';

export const forecastSetReturnRangeTableData = (
  assetsPayloads: Asset[],
  assetsCurves: AssetCurve[],
  selectedOptions: Asset,
  tableDataLength: number
): ForecastTableData[] => {
  const asset = assetsPayloads.filter((asset: Asset) => asset.name === selectedOptions.name)[0];

  return ([
    {
      id: tableDataLength + 1,
      minMaxLabel: MEAN_RETURN_TYPE_LABELS.max,
      componentType: COMPONENTS_TYPE_IDS.meanReturnRangeMax,
      assetName: selectedOptions?.name,
      ...createMonthData(assetsCurves, null, null, asset.id, false)
    },
    {
      id: tableDataLength + 2,
      minMaxLabel: MEAN_RETURN_TYPE_LABELS.min,
      componentType: COMPONENTS_TYPE_IDS.meanReturnRangeMin,
      assetName: selectedOptions?.name,
      ...createMonthData(assetsCurves, null, null, asset.id, false)
    }
  ]);
};
