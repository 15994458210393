import React, { useState, useEffect } from 'react';

import { GridRowsProp } from '@mui/x-data-grid';

import {
  Asset,
  AssetCurve,
  Components,
  IScenarioDetails,
  ScenarioDetailsForm
} from 'interfaces';

import { DEFAULT_SCENARIO_TYPE, FORECAST_TYPES_IDS } from 'constant';

import Button from 'components/ui/Button';
import Icons from 'components/ui/Icons';
import FormHeading from 'components/ui/FormHeading';

import ScenarioForecastInputs from 'components/ScenarioForecastInputs';
import ScenarioDetails from 'components/ScenarioDetails';
import ScenarioForecast from 'components/ScenarioForecast';
import PageButtonsWrapper from 'components/PageButtonsWrapper';

import {
  ScenarioForecastInputsWrapper
} from './CreateScenario.styles';

export interface CreateScenarioProps {
  assetsPayloads: Asset[];
  assetsCurves: AssetCurve[];
  scenarioDetails: IScenarioDetails;
  returnTableData: Components[];
  returnRangeTableData: Components[];
  initialValues: ScenarioDetailsForm;
  createScenario: (data: any) => void;
}

const CreateScenario = (props: CreateScenarioProps): JSX.Element => {
  const [forecastType, setForecastType] = useState<any>(DEFAULT_SCENARIO_TYPE);
  const [scenarioDetails, setScenarioDetails] = useState<IScenarioDetails>(props.scenarioDetails);

  const [returnTableData, setReturnTableData] = React.useState<GridRowsProp>(props.returnTableData);
  const [returnRangeTableData, setReturnRangeTableData] = React.useState<GridRowsProp>(props.returnRangeTableData);

  useEffect(() => {
    setScenarioDetails(props.scenarioDetails);
  }, [props.scenarioDetails]);

  useEffect(() => {
    setReturnTableData(props.returnTableData);
  }, [props.returnTableData]);

  useEffect(() => {
    setReturnRangeTableData(props.returnRangeTableData);
  }, [props.returnRangeTableData]);

  return (
    <React.Fragment>
      <ScenarioDetails
        initialValues={props.initialValues}
        scenarioDetails={scenarioDetails}
        setScenarioDetails={setScenarioDetails}
      />
      <ScenarioForecast
        returnTableData={returnTableData}
        setReturnTableData={setReturnTableData}
        returnRangeTableData={returnRangeTableData}
        setReturnRangeTableData={setReturnRangeTableData}
        assetsPayloads={props.assetsPayloads}
        assetsCurves={props.assetsCurves}
        forecastType={forecastType}
        setForecastType={setForecastType}
      />
      {
        returnTableData.length > 0 || returnRangeTableData.length > 0 ? (
          <ScenarioForecastInputsWrapper>
            <FormHeading>
              Set Forecast Inputs
            </FormHeading>
            {
              returnTableData.length > 0 ? (
                <ScenarioForecastInputs
                  tableData={returnTableData}
                  setTableData={setReturnTableData}
                  staticForecastType={FORECAST_TYPES_IDS.meanReturn}
                />
              ) : null
            }
            {
              returnRangeTableData.length > 0 ? (
                <ScenarioForecastInputs
                  tableData={returnRangeTableData}
                  setTableData={setReturnRangeTableData}
                  staticForecastType={FORECAST_TYPES_IDS.meanReturnRange}
                />
              ) : null
            }
          </ScenarioForecastInputsWrapper>
        ) : null
      }
      <PageButtonsWrapper>
        <Button
          onClick={() => {
            props.createScenario({
              scenarioDetails,
              returnTableData: [
                ...returnTableData,
                ...returnRangeTableData
              ]
            });
          }}
          color="secondary"
          disabled={!(returnTableData.length > 0 || returnRangeTableData.length > 0) || !scenarioDetails.isValid}
          endIcon={(
            <Icons iconType="checkmark" size={7} />
          )}
        >
          {scenarioDetails.id ? 'Update Scenario' : 'Create Scenario'}
        </Button>
      </PageButtonsWrapper>
    </React.Fragment>
  );
};

export default CreateScenario;
