import { textRenderer } from 'components/DashboardAnalyticsTable/utils/textFormatter';

export const assetsColumnDefs = [
  {
    field: 'assetName',
    headerName: 'Asset name',
    flex: 1,
    minWidth: 300,
    maxWidth: 400,
    cellRenderer: textRenderer
  }
];
